import React,{useState,useEffect} from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Profile from '../images/filofax.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Server } from '../Config/Server';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const override = css`
  display: block;
  margin: 0 auto;

`;
function Login() {
  const[email,setEmail]=useState("");
const[password,setPassword]=useState("");
const[isLoading,setIsLoading]=useState(false);
const[googleUser,setGoogleUser]=useState([])
const[googleEmail,setGoogleEmail]=useState("")
const[googleVerify,setGoogleVerify]=useState("")
const[flag,setFlag]=useState("");
const [currentUserTimezone, setCurrentUserTimezone] = useState('');
const [timezones, setTimezones] = useState([]);

const fetchTimezones = async () => {
  try {
    const response = await fetch(`${Server}/meeting/timezone`);
    const data = await response.json();
    setTimezones(data);
  } catch (error) {
    console.error('Error fetching timezones:', error);
  }
};



useEffect(() => {
  fetchTimezones();
   
   const intervalId = setInterval(fetchTimezones, 60000);
   setCurrentUserTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
   
   return () => clearInterval(intervalId);
   
}, []);

if(currentUserTimezone==="Asia/Calcutta"){
setCurrentUserTimezone("Asia/Kolkata")
}

const handleLogin=(e)=>{
  setFlag(false)
    
setIsLoading(true);

  const formdata={
      
      email:email,
      password:password,
      
  }
  console.log(formdata,"data submitted is ")
  const call=axios.post(`${Server}/meeting/login`,formdata).then((data)=>{
console.log(data.data.first_name,"response is ==>>")
const name = data.data.first_name +" "+ data.data.last_name;
console.log(name,"name is")

 localStorage.setItem("Name", JSON.stringify(name));

 localStorage.setItem("LoginId", JSON.stringify(data.data.id));
 localStorage.setItem("Email", JSON.stringify(data.data.email));
      setTimeout(() => {
        window.location.href = "/";
        setIsLoading(false)
      }, 3000);
  }).catch((error)=>{
    setIsLoading(false)
    console.log(error.response.data.errors[0].message)
       toast.error(error.response.data.errors[0].message);
  })
}
useEffect(() => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
 
      handleLogin();
    }
  };

  window.addEventListener("keydown", handleKeyPress);
  return () => {
    window.removeEventListener("keydown", handleKeyPress);
  };
}, [email,password]);






const [ user, setUser ] = useState([]);
const [ profile, setProfile ] = useState([]);

const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});

useEffect(
    () => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async(res) => {
                    setProfile(res.data);
                    // localStorage.setItem("Name", JSON.stringify(res.data.name));

                    // localStorage.setItem("LoginId", JSON.stringify(res.data.id));
                    // localStorage.setItem("Email", JSON.stringify(res.data.email));

                    const formData = {
                      name: res.data.name,
                      // id:res.data.id,
                      email:res.data.email,
                      timezone:currentUserTimezone,
                    };
                   
                    await axios
                      .post(`${Server}/meeting/googleSignup`, formData)
                      .then((data) => {
                        console.log(data,"result is ==>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
                         console.log(data.data.data.id,"data fuweuihfia ===>>")
                         const lastt=data.data.data.last_name=="undefined" || "null"?"":data.data.data.last_name;
const name = data.data.data.first_name +" "+ lastt;
console.log(name,"name is =============>>>>>")
                   localStorage.setItem("Name", JSON.stringify(name));

                   localStorage.setItem("LoginId", JSON.stringify(data.data.data.id));
                 localStorage.setItem("Email", JSON.stringify(data.data.data.email));



                 const name1=localStorage.getItem("Name");

//                     console.log(name1 , "name is ==========>>>>>>>>")
    if(name1){    


 console.log("i reached here or not ===>>>")
                    


                      setTimeout(() => {
                        window.location.href = "/"; // Navigate to the login page
                        setIsLoading(false);
                      }, 500);

                     }


                        // toast.success('You are Registered Successfully!');
                      
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        console.log(error.response.data.errors[0].message);
                        toast.error(error.response.data.errors[0].message);
                      });







                        //  setTimeout(() => {
                        //    window.location.href = "/";
                        //    setIsLoading(false)
                        //  }, 1000);
                })
                .catch((err) => console.log(err));
        }
        console.log(profile,"profile is as follows ")
    },
    [ user ]
);







console.log(user,"user is ======>>>>>>>")
console.log(profile,"profile is ======>>>>>>>")


  return (
    <div className="background">
      <div className="form-container main ">
        <form className="myform" >
 <div className='flex-center'>
<img src={Profile}  className="profile" />
</div> 
          
          <div className="mb-3 ">
            <label htmlFor="formBasicEmail" className='form-label'>Email</label>
            <input type="email" id="formBasicEmail" required className="custom-input form-control"
            onChange={(e)=>{
              setEmail(e.target.value)
          }}  />
           
          </div>
          <div className="mb-3">
            <label htmlFor="formBasicPassword">Password</label>
            <input type="password" id="formBasicPassword" required className="custom-input form-control log_pw"  onChange={(e)=>{
                setPassword(e.target.value)
            }}/>
          </div>

          
      <div className='flex-center'>
      <button className="login-button" type="button" onClick={handleLogin}>{isLoading?<ClipLoader size={20} color={'#ffffff'}/>:"Login"}</button>
      </div>
      <div className="mt-3 p-3 text-center">
        <span className='text-center'>Don't have an account? <Link to="/signup" className='sign_up'>Sign up</Link></span>
        {/* <button onClick={login}  type="button" class="login-with-google-btn "> Sign in with Google </button> */}
        {/* <div id="googleSignInButton" className='mt-2'></div> */}
        <button class="google-button" onClick={login} type="button" >
  <div class="google-icon-wrapper">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48">
      <g>
        <path fill="#EA4335"
          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
        </path>
        <path fill="#4285F4"
          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
        </path>
        <path fill="#FBBC05"
          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
        </path>
        <path fill="#34A853"
          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
        </path>
        <path fill="none" d="M0 0h48v48H0z"></path>
      </g>
    </svg>
  </div>
  <p class="google-button-text">Sign in with Google</p>
</button>
      </div>
     
        </form>
      
        
        
        <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
        pauseOnHover
        // className="toast-container"
      />
      </div>
     
    </div>
  );



}

export default Login