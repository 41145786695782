import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Server } from '../Config/Server';


const override = css`
  display: block;
  margin: 0 auto;
`;

function Signup() {
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [currentUserTimezone, setCurrentUserTimezone] = useState('');
  const [timezones, setTimezones] = useState([]);
  

  const fetchTimezones = async () => {
    try {
      const response = await fetch(`${Server}/meeting/timezone`);
      const data = await response.json();
      setTimezones(data);
    } catch (error) {
      console.error('Error fetching timezones:', error);
    }
  };



  useEffect(() => {
    fetchTimezones();
     
     const intervalId = setInterval(fetchTimezones, 60000);
     setCurrentUserTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
     
     return () => clearInterval(intervalId);
     
  }, []);

if(currentUserTimezone==="Asia/Calcutta"){
  setCurrentUserTimezone("Asia/Kolkata")
}

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [firstName, lastName, email, password, confirm, phone]);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true);
    const formData = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      confirm_password: confirm,
      phone,
      timezone:currentUserTimezone,
    };

  
    console.log(formData, 'data submitted is');
    axios
      .post(`${Server}/meeting/signup`, formData)
      .then((data) => {
        toast.success('You are Registered Successfully!');
        setTimeout(() => {
          navigate('/'); // Navigate to the login page
          setIsLoading(false);
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response.data.errors[0].message);
        toast.error(error.response.data.errors[0].message);
      });
  };

  return (
    <div className="background">
      <div className="form-container main">
        <form className="myform" onSubmit={handleSubmit}>
          <div className="signup-heading">Sign Up</div>
          <div className="flex">
            <div className="mb-3">
              <label htmlFor="formBasicEmail" className="form-label">First Name</label>
              <input
                type="text"
                id="formBasicEmail"
                className="custom-input form-control form-control-cust"
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="formBasicEmail" className="form-label">Last Name</label>
              <input
                type="text"
                id="formBasicEmail"
                className="custom-input form-control form-control-cust"
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="formBasicPassword" className="form-label">Contact No.</label>
            <input
              type="number"
              id="formBasicPassword"
              className="custom-input form-control"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formBasicEmail" className="form-label">Email</label>
            <input
              type="email"
              id="formBasicEmail"
              className="custom-input form-control"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="formBasicPassword" className="form-label">Password</label>
            <input
              type="password"
              id="formBasicPassword"
              className="custom-input form-control"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formBasicPassword" className="form-label">Confirm Password</label>
            <input
              type="password"
              id="formBasicPassword"
              className="custom-input form-control"
              onChange={(e) => setConfirm(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <span>Already have an account? <Link to="/" className='sign_up'>Login</Link></span>
          </div>
          <div className="flex-center">
            <button className="signup-button" type="submit">
              {isLoading ? <ClipLoader size={20} color="ffffff" /> : 'Sign Up'}
            </button>
          </div>
        </form>
    
        <ToastContainer />
      </div>
    </div>
  );
}

export default Signup;
