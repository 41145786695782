import React, { useState, useMemo, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import trash from "../images/trash.svg"
import { Server } from '../Config/Server';
import Card from 'react-bootstrap/Card';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import Col from 'react-bootstrap/Col';
import { RiDeleteBin5Fill } from "react-icons/ri";
import axios from 'axios';
import Dashboard from './Dashboard';
import Delete from'../images/delete.svg'
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



const TicketReservations = () => {
    const storedVenueId = localStorage.getItem('Venue');
    const [venueId, setVenueId] = useState(storedVenueId)
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteBookingId,setDeleteBookingId]=useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const user_id=localStorage.getItem('LoginId');
  const sanitizedUser = user_id.replace(/"/g, '');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = async(id) => {

    console.log(id , "id is ==>>")
  
  
  
    try {
      // const res = await axios.post(`${Server}/meeting/eventList`,req)
      const res = await axios.delete(`${Server}/meeting/del/${id}`).then(()=>{
        loadBooking();
      })
      // const req = await axios.post(`${Server}/meeting/deleteEvent`,req)
      console.log("data has been deleting ")
      
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
  
  
  
  
  
  
    
    };

  const cardStyles = {
    boxShadow: '0 0 20px rgba(255, 105, 180, 0.8)', // Bright pink shadow
    borderRadius: '8px',
    padding: '16px',
    backgroundColor:'transparent'
    // Set the desired background color
    // Other styling properties...
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    
 
  loadBooking();
 
    
  }, []);


  const openDeleteModal = (bookingId) => {
    setDeleteBookingId(bookingId)
    setIsDeleteOpen(true);
  };





  const closeDeleteModal = () => {
    setDeleteBookingId(null)
    setIsDeleteOpen(false);
    // section();
  };

  const loadBooking = async () => {
    const req = {
    user_id:sanitizedUser
    };
    try {
      // const res = await axios.post(`${Server}/meeting/eventList`,req)
      const res = await axios.post(`${Server}/meeting/eventsByUser`,req)
      
      console.log(res)
      setData(res.data.Info);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const columns = useMemo(
    () => [
      { Header: 'Host', accessor: 'host' },
    //   {
    //     Header: 'NAME',
    //     accessor: 'first_name',
    //     Cell: ({ row }) => {
    //       const fullName = `${row.original.first_name} ${row.original.last_name}`;
    //       return <span>{fullName}</span>;
    //     },
    //   },
      { Header: 'DATE', accessor: 'custom_date' },
      { Header: 'DAY', accessor: 'day' },
      { Header: 'DURATION', accessor: 'duration' },
      { Header: 'FROM', accessor: 'time' },
      { Header: 'TO', accessor: 'time_slot' },
      { Header: 'TIMEZONE', accessor: 'timezone' },
      { Header: 'LEAD', accessor: 'lead_name' },
      { Header: 'LEAD EMAIL', accessor: 'lead_email' },
    //   {
    //     Header: 'ACTION',
    //     accessor: 'id', // Assuming 'id' is the property containing the event ID
    //     Cell: ({ value }) => (
          
    //        <div className="flex items-center justify"  style={{ marginRight: '10px', display: 'block', margin: 'auto'}}>
    //         {/* <Button
    //         // onClick={() => handleButtonClick(value)}
    //         className="your-button-styles"
    //         color="your-button-color"
    //       >
    //         Click Me
    //       </Button> */}
      
    //        <button className="mx-2 text-[white] hover:text-red-700 text-[25px]" onClick={()=>{
    //          openDeleteModal(value)
    //        }}>
    //        <RiDeleteBin5Fill />
    //        </button>
           
         
    //        {/* <AddTable isTableOpen={isTableOpen} onRequestTableClose={closeTableModal} />
    //        <DeleteSection isOpen={isDeleteOpen} onRequestClose={closeDeleteModal} sectionId={deleteSectionId}/>
    //        <EditSection isOpen={isEditSectionOpen} onRequestClose={closeEditSectionModal} sectionId={editSectionId} /> */}
    //        </div>
    //     ),
    //   },
      // Add more columns as needed
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageIndex: currentPageIndex,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setGlobalFilter: setTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const pageIndex = currentPageIndex !== undefined ? currentPageIndex : 0;

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    setTableGlobalFilter(e.target.value);
  };

  const filteredData = useMemo(
    () =>
      data.filter((row) =>
        columns.some(
          (column) =>
            row[column.id] !== undefined &&
            String(row[column.id]).toLowerCase().includes(globalFilter.toLowerCase())
        )
      ),
    [data, columns, globalFilter, pageIndex]
  );
  const backdropStyles = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("https://images.unsplash.com/photo-1556035511-3168381ea4d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnaHQlMjBjbHVifGVufDB8fDB8fHww")', // Replace with your image URL
    backgroundSize: 'cover',
    filter: 'blur(10px)', // Adjust the blur intensity
    zIndex: -1,
  };



  console.log(data,"data ==>>")
  return (
    <>
 
 <div className="page-main">
        <div className='page-inner-main'>
             <div className='email-div'>
    
   
            <div  className="inner-booking">
      <div >
        <div className='grid gap-x-8 gap-y-4'>

     
        <div className="flex flex-row font-poppins booking_serch items-center justify-between mt-10 ">
          <text
            className="txt_booking "
            
          >
            Events
          </text>
          <div className="bg-transparent w-[200px]  ">
          {/* <button
    className="  btn_previus  ms-auto margin1" onClick={openModal}>
    Add Event
    
    
    </button> */}
            {/* <input
              type="text"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
              placeholder="Search...."
              className="w-full p-2 search_bar"
            /> */}
          </div>
        </div>



        {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%", margin:"20px"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) : ( 
          data.length!==0?(
         <>
      <div className='row'>
          {data.map((event, index) => (
        <Col sm={6} md={4} lg={4} className='demo py-2' key={index} data-id={event.id}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <div className='bg-box'>
                <div className='date flex'>
                  <div className='date_icn'>
                  <span>{event.location}</span>
                  <span>{event.duration} mins</span>
                  </div>
                <div className='dlte_img'
                onClick={() => handleDelete(event.id)}
                 >
                  {/* <span type="button" className='dlte_btn'>Delete</span> */}
                  {/* <img src={trash} className='dlt_img'/>viewBox="0 0 256 256 */}
              

<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
	 width="30px"  viewBox="0 0 482.428 482.429" 
	>
<g>
	<g>
		<path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098
			c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117
			h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828
			C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879
			C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096
			c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266
			c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979
			V115.744z"/>
		<path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07
			c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"/>
		<path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07
			c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"/>
		<path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07
			c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"/>
	</g>
</g>
</svg>
                </div>
                </div> 
               
                <Card.Title>{event.event_name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{event.subtitle}</Card.Subtitle>
                <Card.Text>
                  {event.text}
                </Card.Text>
              </div>
              <Card.Link href={event.url}>Event Link</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        
      ))}
      </div> 

{/* <div className='row'>
  {data.map((event, index) => (
    <Col sm={6} md={4} lg={4} className='demo py-2' key={index} data-id={event.id}>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <div className='bg-box'>
            <div className='date'>
              <span>{event.location}</span>
              <span>{event.duration} mins</span>
            </div>
            <Card.Title>{event.event_name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{event.subtitle}</Card.Subtitle>
            <Card.Text>
              {event.text}
            </Card.Text>
          </div>
          <Card.Link href={event.url}>Event Link</Card.Link>
        </Card.Body>
      </Card>
    </Col>
  ))}
</div> */}



         </>
        // <table {...getTableProps()} className=" table_data " >
        // <thead className='bg_table'>
        //     {headerGroups.map((headerGroup) => (
        //       <tr  className="table_row" {...headerGroup.getHeaderGroupProps()}>
        //         {headerGroup.headers.map((column) => (
        //           <th key={column.id} className="table_head">
        //             {column.render('Header')}
        //           </th>
        //         ))}
        //       </tr>
        //     ))}
        //   </thead>

          
        //   <tbody {...getTableBodyProps()}>
        //     {filteredData.map((row) => {
        //       prepareRow(row);
        //       return (
        //         <tr {...row.getRowProps()} className="table_row_data">
        //           {row.cells.map((cell) => (
        //             <td key={cell.column.id} {...cell.getCellProps()} className="p-4">
        //               {cell.render('Cell')}
        //             </td>
        //           ))}
        //         </tr>
        //       );
        //     })}
        //     {page.map((row) => {
        //       prepareRow(row);
        //       return (
        //         <tr {...row.getRowProps()} className="table_row_data">
        //           {row.cells.map((cell) => (
        //             <td key={cell.column.id} {...cell.getCellProps()} className="p-4 text-center justify-center">
        //               {cell.render('Cell')}
        //             </td>
        //           ))}
        //         </tr>
        //       );
        //     })}
        //   </tbody>
        // </table>
):
<div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: '350px', width:"100%"}}>

<h1 className='messages'> No Events Available!</h1>
</div>








)

}





        {/* <div className="flex-shrink-0 p-4">
        <div className="pagination mt-4 mb-10 text-end">
      <button
    className="  btn_previus  ms-auto"
    
    onClick={() => {previousPage()
      setIndex(index - 1)}}
      disabled={!canPreviousPage}
    > Previous
    
    </button>
    
    {' '}
    <span  className="page_m">
      Page{' '}
      <strong>
        {index + 1} of {pageOptions.length}
      </strong>{' '}
    </span>

    <button
    className="  btn-next btn_previus "
    onClick={() => {nextPage();
      setIndex(index + 1)}}
    disabled={!canNextPage}
    > Next
    
    </button>
    
    {' '}
  </div>
        </div> */}
        </div>
      </div>
    </div>
    
  {/* <SingleEvent isOpen={isSingleEventModalOpen}  onRequestClose={closeIndividualEventModal} eventId={eventId}/> */}
    
    
    <Dashboard isOpen={isModalOpen}  onRequestClose={closeModal} />

</div>
    </div>
    </div>
    
    </>
  );
};

export default TicketReservations;
