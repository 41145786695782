import { useEffect, useState } from 'react';
import { Server } from '../../Config/Server';
import axios from 'axios';
// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options
// const columnChartOptions = {
//   chart: {
//     type: 'bar',
//     height: 430,
//     toolbar: {
//       show: false
//     }
//   },
//   plotOptions: {
//     bar: {
//       columnWidth: '30%',
//       borderRadius: 4
//     }
//   },
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     show: true,
//     width: 8,
//     colors: ['transparent']
//   },
//   xaxis: {
//     categories: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//   },
//   yaxis: {
//     title: {
//       text: '$ (thousands)'
//     }
//   },
//   fill: {
//     opacity: 1
//   },
//   tooltip: {
//     y: {
//       formatter(val) {
//         return `$ ${val} thousands`;
//       }
//     }
//   },
//   legend: {
//     show: true,
//     fontFamily: `'Public Sans', sans-serif`,
//     offsetX: 10,
//     offsetY: 10,
//     labels: {
//       useSeriesColors: false
//     },
//     markers: {
//       width: 16,
//       height: 16,
//       radius: '50%',
//       offsexX: 2,
//       offsexY: 2
//     },
//     itemMargin: {
//       horizontal: 15,
//       vertical: 50
//     }
//   },
//   responsive: [
//     {
//       breakpoint: 600,
//       options: {
//         yaxis: {
//           show: false
//         }
//       }
//     }
//   ]
// };
const columnChartOptions = {
  chart: {
    type: 'bar',
    height: 430,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '30%',
      borderRadius: 4
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 8,
    colors: ['transparent']
  },
  xaxis: {
    type: 'category', // Ensure it's treated as categories, not numbers
    categories: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'], // Days of the week
  },
  yaxis: {
    title: {
      text: '$ (thousands)'
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter(val) {
        return `$ ${val} thousands`;
      }
    }
  },
  legend: {
    show: true,
    fontFamily: `'Public Sans', sans-serif`,
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false
    },
    markers: {
      width: 16,
      height: 16,
      radius: '50%',
      offsexX: 2,
      offsexY: 2
    },
    itemMargin: {
      horizontal: 15,
      vertical: 50
    }
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: false
        }
      }
    }
  ]
};

// ==============================|| SALES COLUMN CHART ||============================== //

const SalesColumnChart = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [series, setSeries] = useState([]);
  const id = localStorage.getItem('LoginId')
  const loadData = async () => {
    try {
      const res = await axios.get(`${Server}/meeting/oneChart/${id}`);
      setData(res.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const loadData1 = async () => {
    try {
      const res = await axios.get(`${Server}/meeting/MeetingChartGroup/${id}`);
      setData1(res.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    loadData();
    loadData1();
  }, []);

  useEffect(() => {
    if (data.length && data1.length) {
      // Ensure the data has exactly 7 points, if not handle appropriately
      if (data.length === 7 && data1.length === 7) {
        setSeries([
          {
            name: 'One To One',
            data: data
          },
          {
            name: 'One To Many',
            data: data1
          }
        ]);
      } else {
        console.error("Data length does not match the number of days (7).");
      }
    }
  }, [data, data1]);
  

  const theme = useTheme();
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;
  const warning = theme.palette.warning.main;
  const primaryMain = theme.palette.primary.main;

  const [options, setOptions] = useState(columnChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [warning, primaryMain],
      xaxis: {
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        labels: {
          colors: 'grey.500'
        }
      }
    }));
  }, [primary, secondary, line, warning, primaryMain]);

  return (
    <div id="chart">
      {series.length > 0 && (
        <ReactApexChart options={options} series={series} type="bar" height={430} />
      )}
    </div>
  );
};

export default SalesColumnChart;
