// import React, { useState, useRef ,useEffect} from 'react';
// import { Server ,ReactServer} from '../Config/Server';
// import axios from 'axios';
// import { ToastContainer, toast } from "react-toastify";
// import {Link} from "react-router-dom";
// import { RxOpenInNewWindow } from "react-icons/rx";
// import Modal from 'react-modal';
// function Dashboard({ isOpen, onRequestClose }) {
//     const [eventName, setEventName] = useState('');
//     const [siteUrl, setSiteUrl] = useState('');
//     const [duration, setDuration] = useState('30 mins');
//     const [location, setLocation] = useState('');

    
  


//  const user_id=localStorage.getItem('LoginId')
//  const sanitizedUser = user_id.replace(/"/g, '');



//  useEffect(()=>{
//   eventCreated();
//  },[])
//   const eventCreated=()=>{
//     const formdata={
     
//       user_id:sanitizedUser,

//   }

 



//   const call=axios.post(`${Server}/meeting/eventsByUser`,formdata).then((data)=>{
//       console.log(data,"response is ==>>")
//     //    toast.success("Event is Created Successfuly!");
//     // console.log(data.data.url,"url is ")
//     // setSiteUrl(data.data.url)
//     //     // setTimeout(() => {
//     //     //       window.location.href = "/";
//     //     //     }, 3000);
//         }).catch((error)=>{
//           // console.log(error.response.data.errors[0].message)
//           //    toast.error(error.response.data.errors[0].message);
//         })
  
//   }





//     const handleSubmit = (e) => {

        
//         e.preventDefault();
//         const formdata={
//             event_name:eventName,
//             user_id:sanitizedUser,

//         }
    
       
     


//         const call=axios.post(`${Server}/meeting/new`,formdata).then((data)=>{
//             console.log(data,"response is ==>>")
//              toast.success("Event is Created Successfuly!");
//           console.log(data.data.url,"url is ")
//           setSiteUrl(data.data.url)
//               // setTimeout(() => {
//               //       window.location.href = "/";
//               //     }, 3000);
//               }).catch((error)=>{
//                 console.log(error.response.data.errors[0].message)
//                    toast.error(error.response.data.errors[0].message);
//               })
        


    
//       };
     
//   const email= localStorage.getItem('Email')
//   const sanitizedEmail = email.replace(/"/g, '');
//       const username = sanitizedEmail.split('@')[0];
//      const url = `${ReactServer}/${username}`;

//   return (
//     <Modal
//     isOpen={isOpen}
//     onRequestClose={onRequestClose}
//     contentLabel="Example Modal"
//     style={{
//       overlay: {
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       },
//       content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         background: 'none',
//         border: 'none',
//         padding: 0,
//         overflow: 'hidden',
//       },
     
//     }}
//   >
//     <div className="page-main">
       
//         <div className='page-inner-main'>
       
//              <div className='email-div'>
               
//                 <div>
//                 <form className="custom-email-form" onSubmit={handleSubmit}>

//       <div className='email-heading'>Event Name</div>
 
//       <div className="mb-3">
//         <label htmlFor="subject" className="form-label margin">Event Name</label>
//         <input
//           type="text"
//           id="eventName"
//           className="form-control white"
//           required
//           value={eventName}
//           onChange={(e) => setEventName(e.target.value)}
//         />
//       </div>

//       <div className="mb-3">
//   <label htmlFor="duration" className="form-label margin">Duration</label>
//   <select
//     id="duration"
//     className="form-control white"
//     required
//     value={duration}
//     onChange={(e) => setDuration(e.target.value)}
//   >
//     <option value="15 mins">15 mins</option>
//     <option value="30 mins">30 mins</option>
//     <option value="45 mins">45 mins</option>
//     <option value="1 hr">1 hr</option>
//   </select>
// </div>

     

//       {/* <div className="mb-3">
//         <label htmlFor="subject" className="form-label margin">Location</label>
//         <input
//           type="text"
//           id="eventName"
//           className="form-control white"
//           required
//           value={eventName}
//           onChange={(e) => setEventName(e.target.value)}
//         />
//       </div> */}
//      <div className="mb-3">
//   <label htmlFor="location" className="form-label margin">Location</label>
//   <select
//     id="location"
//     className="form-control white"
//     required
//     value={location}
//     onChange={(e) => setLocation(e.target.value)}
//   >
//     {/* <option value="Zoom Meeting">Zoom Meeting</option> */}
//     <option value="Google Meet">Google Meet</option>
//   </select>
// </div>
     
//       <div className='btn-div'>
//       <button type="submit" className='email-btn'  >Create</button>
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         theme="light"
//         pauseOnHover
//         // className="toast-container"
//       />
      
//       </div>
//       {siteUrl?
//       <>
//     <Link to={siteUrl}><RxOpenInNewWindow /> Check the Event Calender</Link>
//     </>:null}
      
//     </form>
    
//                 </div>

//              </div>
//         </div>
//     </div>
//     </Modal>
//   )
// }

// export default Dashboard;



import React, { useState, useEffect } from 'react';
import { Server, ReactServer } from '../Config/Server';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { RxOpenInNewWindow } from "react-icons/rx";
import Modal from 'react-modal';
import Preview from './Preview';
import EventType from './EventType';
import admindashboard from"./Admin_dashboard1"
function Dashboard({ isOpen, onRequestClose,type }) {
    const [eventName, setEventName] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [duration, setDuration] = useState('30');
    const [location, setLocation] = useState('Google Meet');

    const user_id = localStorage.getItem('LoginId');
    const sanitizedUser = user_id.replace(/"/g, '');

    useEffect(() => {
        eventCreated();
    }, []);

    const eventCreated = () => {
        const formdata = { user_id: sanitizedUser };
        axios.post(`${Server}/meeting/eventsByUser`, formdata)
            .then((data) => {
                console.log(data, "response is ==>>");
            })
            .catch((error) => {
                // console.log(error.response.data.errors[0].message);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formdata = {
            event_name: eventName,
            user_id: sanitizedUser,
            duration:duration,
            location:location,
            type:type
        };

        axios.post(`${Server}/meeting/new`, formdata)
            .then((data) => {
                toast.success("Event is Created Successfully!");
                setSiteUrl(data.data.url);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].message);
            });
    };

    const email = localStorage.getItem('Email');
    const sanitizedEmail = email.replace(/"/g, '');
    const username = sanitizedEmail.split('@')[0];
    const url = `${ReactServer}/${username}`;
    const resetForm = () => {
      setEventName('');
  
      setDuration('');
      setLocation('');
  };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
              resetForm();
              onRequestClose();
          }}
            contentLabel="Example Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    zIndex:'9',
                },
                content: {
                    top: '5%',
                    left: '50%',
                    // bottom: '5%', // Adjusts the bottom space (5% gap from the bottom of the viewport)
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, 0)', // Centers the modal horizontally, removes vertical centering
                    // transform: 'translate(-50%, -50%)',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    // overflow: 'hidden',
                    overflow: 'auto', // Enables scrolling if content overflows
                    zIndex:'9',
                    maxHeight: '90vh', // Ensures the modal doesn't exceed 90% of the viewport height
                    // width: '80%', // Adjust the width if necessary

                   
                },
            }}
        >
            <div className="page-main" style={{ display: 'flex', height: '70%' }}>
                {/* Left side - Form */}
                <div className="page-inner-main" style={{ flex: 1, padding: '10px' }}>
                    <div className='email-div' style={{ display: 'flex' ,flexDirection:"row" ,gap :'20px' }}>
                        <form className="custom-email-form" onSubmit={handleSubmit}>
                            <div className='email-heading'>Create Event</div>
                            <div className="mb-3">
                                <label htmlFor="eventName" className="form-label margin">Event Name</label>
                                <input
                                    type="text"
                                    id="eventName"
                                    className="form-control white"
                                    required
                                    value={eventName}
                                    onChange={(e) => setEventName(e.target.value)}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="duration" className="form-label margin">Duration</label>
                                <select
                                    id="duration"
                                    className="form-control white"
                                    required
                                    value={duration}
                                    onChange={(e) => setDuration(e.target.value)}
                                >
                                    <option value="15">15 mins</option>
                                    <option value="30">30 mins</option>
                                    <option value="45">45 mins</option>
                                    <option value="60">1 hr</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="location" className="form-label margin">Location</label>
                                <select
                                    id="location"
                                    className="form-control white"
                                    required
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    {/* <option value="Zoom Meeting">Zoom Meeting</option> */}
                                    <option value="Google Meet">Google Meet</option>
                                </select>
                            </div>

                            <div className='btn-div'>
                                <button type="submit" className='email-btn'>Create</button>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={2000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    theme="light"
                                    pauseOnHover
                                />
                            </div>
                            {siteUrl && (
                                <Link to={siteUrl}><RxOpenInNewWindow /> Check the Event Calendar</Link>
                            )}
                        </form>

                        <div className="preview-section" style={{ flex: 1, paddingLeft: '20px', borderLeft: '1px solid #ccc' }}>
                            <Preview
                            eventName={eventName}
                            duration={duration}
                            username={username}
                            />
                                
{/* <Preview/> */}





                   
                    {/* <div className="preview-item">
                        <strong>Event Name:</strong> {eventName || 'No event name provided'}
                    </div>
                    <div className="preview-item">
                        <strong>Duration:</strong> {duration}
                    </div>
                    <div className="preview-item">
                        <strong>Location:</strong> {location}
                    </div> */}
                </div>
                   
  
                
                
                
                
                    </div>
                </div>

                

                {/* Right side - Preview */}
               
            </div>
         
        </Modal>
            
       
    );
    
}

export default Dashboard;
