import { FaUserCircle } from 'react-icons/fa'; // User icon from react-icons
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect } from 'react';
import Logout from ".././images/sign-out-alt.svg";
import { Server ,ReactServer} from '../Config/Server';
import {Link} from "react-router-dom";
// import { GiHandWave } from 'react-icons/gi'; // Import the wave icon
import { FaRegHandPaper } from 'react-icons/fa'; // Import from Font Awesome
// Helper function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return ''; // Handle empty or undefined strings
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function Header() {
  
  const name = localStorage.getItem("Name")
  const sanitizedName = name.replace(/"/g, '');

  const email= localStorage.getItem('Email')
  const sanitizedEmail = email.replace(/"/g, '');
      const username = sanitizedEmail.split('@')[0];
     const url = `${ReactServer}/${username}`;




  const logout=()=>{
    localStorage.clear();
    window.location.href = "/";
  }
  const [waveClass, setWaveClass] = useState('');
 // Capitalize the username and sanitizedName
 const capitalizedUsername = capitalizeFirstLetter(username);
 const capitalizedSanitizedName = capitalizeFirstLetter(sanitizedName);

  // Trigger animation on page load (component mount)
  useEffect(() => {
    setWaveClass('wave-hand'); // Add the wave class to trigger the animation
  }, []);
  return (
  // <div className='header flex justify-between items-center p-4 bg-gray-200'>
  //   <div className='header-user flex items-center space-x-3'>
  //   {sanitizedName}
  //   <Link to={url}>{ReactServer}/{username}</Link>
  //   </div>
  <div className="header ">
      {/* Greeting and User */}
      <div className="header-user ">
        {/* <FaUserCircle size={32} /> User icon */}
        {/* <span role="img" aria-label="wave">👋</span> Waving hand emoji */}
        {/* <FaRegHandPaper size={24} className={waveClass} /> Add the class here */}
        <span> 
         Hey <span role="img" aria-label="wave" className={waveClass}>👋</span>, {capitalizedSanitizedName}</span> {/* {sanitizedName} Show sanitized username */}
        <Link to={url} className="text-blue-500">
        {ReactServer}/{capitalizedUsername} {/* Capitalized username */}

          {/* {ReactServer}/{username} Dynamic Link */}
        </Link>
      </div>
 <button onClick={logout} className='logout' >
 <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M11.476,15a1,1,0,0,0-1,1v3a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7.476a3,3,0,0,1,3,3V8a1,1,0,0,0,2,0V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7.476a5.006,5.006,0,0,0,5-5V16A1,1,0,0,0,11.476,15Z"/><path d="M22.867,9.879,18.281,5.293a1,1,0,1,0-1.414,1.414l4.262,4.263L6,11a1,1,0,0,0,0,2H6l15.188-.031-4.323,4.324a1,1,0,1,0,1.414,1.414l4.586-4.586A3,3,0,0,0,22.867,9.879Z"/></svg>

 </button>
  
   
  </div>
  );
}

export default Header;