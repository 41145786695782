import React, { useState, useRef ,useEffect} from 'react';
import { Server } from '../Config/Server';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
function CustomEmail() {
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const bodyRef = useRef(null);


    useEffect(() => {
      if (bodyRef.current) {
        adjustTextareaHeight(bodyRef.current);
      }
    }, [body]);
  


 const user_id=localStorage.getItem('LoginId')
    const handleSubmit = (e) => {

        const sanitizedUser = user_id.replace(/"/g, '');
        e.preventDefault();
        const formdata={
            subject:subject,
            body:body,
            user_id:sanitizedUser
        }
    
        console.log('Subject:', subject);
        console.log('Body:', body);
     


        const call=axios.post(`${Server}/meeting/email`,formdata).then((data)=>{
            console.log(data,"response is ==>>")
             toast.success("Email is Created Successfuly!");
              setTimeout(() => {
                    window.location.href = "/email";
                  }, 3000);
              }).catch((error)=>{
                console.log(error.response.data.errors[0].message)
                   toast.error(error.response.data.errors[0].message);
              })
        


    
      };
     
  const handleBodyChange = (e) => {
    setBody(e.target.value);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.setProperty('height', `${textarea.scrollHeight}px`, 'important');
  };

    
  return (
    <div className="page-main">
        <div className='page-inner-main'>
             <div className='email-div'>
                <div>
                <form className="custom-email-form" onSubmit={handleSubmit}>
      <div className='email-heading'>Custom Email</div>
 
      <div className="mb-3">
        <label htmlFor="subject" className="form-label margin">Subject</label>
        <input
          type="text"
          id="subject"
          className="form-control white"
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="body" className="form-label margin">Body</label>
        <textarea
          id="body"
          className="form-control white"
          required
          rows="5"
          ref={bodyRef}
          value={body}
          onChange={handleBodyChange}
          style={{ overflow: 'hidden', resize: 'none', maxHeight: '40vh', whiteSpace: 'pre-wrap',overflowWrap: 'break-word'  }}
        />
      </div>
      <div className='btn-div'>
      <button type="submit" className='email-btn'  >Submit</button>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
        pauseOnHover
        // className="toast-container"
      />
      
      </div>
      
    </form>
                </div>

             </div>
        </div>
    </div>
  )
}

export default CustomEmail