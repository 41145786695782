import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Server } from '../Config/Server';
import add from "../images/add.svg"
import trash from "../images/trash.svg"
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
const override = css`
  display: block;
  margin: 0 auto;

`;

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const daysOf = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      times.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();
function Accessability() {

  const user_id=localStorage.getItem('LoginId');
  const sanitizedUser = user_id.replace(/"/g, '');
  const initialAvailabilities = daysOf.map(day => ({
    day,
    checked: false, // Mark each day as available by default
    slots: [{ startTime: '09:00', endTime: '18:00' }] // Default 9 AM to 6 PM slot
  }));

  const [availabilities, setAvailabilities] = useState(initialAvailabilities);
  const [timezones, setTimezones] = useState([]);
  const [currentUserTimezone, setCurrentUserTimezone] = useState('');
  const[isLoading,setIsLoading]=useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedSourceDay, setSelectedSourceDay] = useState("");
  const [showCopyDropdown, setShowCopyDropdown] = useState(false); // State to control dropdown visibility
  const [isChecked, setIsChecked] = useState(false);
  const handleTimeChange = (dayIndex, slotIndex, field, value) => {
    const newAvailabilities = availabilities.map((availability, index) => {
      if (index === dayIndex) {
        const newSlots = availability.slots.map((slot, sIndex) => {
          if (sIndex === slotIndex) {
            return { ...slot, [field]: value };
          }
          return slot;
        });
        return { ...availability, slots: newSlots };
      }
      return availability;
    });
    setAvailabilities(newAvailabilities);
  };

  const addSlot = (dayIndex) => {
    const newAvailabilities = availabilities.map((availability, index) => {
      if (index === dayIndex) {
        return { ...availability, slots: [...availability.slots, { startTime: '', endTime: '' }] };
      }
      return availability;
    });
    setAvailabilities(newAvailabilities);
  };

  const handleCheckboxChange = (index) => {
    const newAvailabilities = [...availabilities];
    newAvailabilities[index].checked = !newAvailabilities[index].checked;
    setAvailabilities(newAvailabilities);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const loginId = localStorage.getItem("LoginId")
    const uid = loginId.replace(/"/g, '');
    
    const filteredAvailabilities = availabilities
    .filter(availability => availability.checked)
    .map(availability => ({
      day: availability.day,
      slots: availability.slots.filter(slot => slot.startTime && slot.endTime)
    }))
    .filter(availability => availability.slots.length > 0);
    e.preventDefault();
    try {
      const response = await axios.post(`${Server}/meeting/avail`, { filteredAvailabilities,currentUserTimezone,uid });
       // On success
    toast.success(" Availability  Saved Successfully!");
    
    } catch (error) {
     
      setIsLoading(false)
      // console.error('Error saving availabilities:', error);
      toast.error(error.response?.data?.errors?.[0]?.message || 'Error saving availabilities.');
    }
    finally {
   
    
      setIsLoading(false); // Ensure loading state is reset after the request
      // console.log('Availabilities saved:', response.data);
    }
  };






  const handleTimezoneChange = (event) => {
    setCurrentUserTimezone(event.target.value);
  
  };
  useEffect(()=>{
    if(currentUserTimezone!="undefined")
    stored();
  },[currentUserTimezone])

const convertedData=()=>{




}







const stored=()=>{
  const formdata={
user_id:sanitizedUser,
timezone:currentUserTimezone
}

if(currentUserTimezone)
{


  const call=axios.post(`${Server}/meeting/storedAvailability`,formdata).then((data)=>{
   
    setCurrentUserTimezone(data.data.clientAvailability[0].timezone)
    const desiredAvailability=transformAvailability(data.data.clientAvailability)
    console.log(desiredAvailability,"desired avaialability")
    setAvailabilities(desiredAvailability)
    //  toast.success("Email is Created Successfuly!");
    //   setTimeout(() => {
    //         window.location.href = "/email";
    //       }, 3000);
      }).catch((error)=>{
        // console.log(error.response.data.errors[0].message)
        //    toast.error(error.response.data.errors[0].message);
      })

}





}

  useEffect(() => {
    fetchTimezones();
     
     const intervalId = setInterval(fetchTimezones, 60000);
     setCurrentUserTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
     
     return () => clearInterval(intervalId);
     
  }, []);


if(currentUserTimezone==="Asia/Calcutta"){
  setCurrentUserTimezone("Asia/Kolkata")
}
const removeSlot = (dayIndex, slotIndex) => {
  const newAvailabilities = availabilities.map((availability, index) => {
    if (index === dayIndex) {
      const newSlots = availability.slots.filter((_, sIndex) => sIndex !== slotIndex);
      return { ...availability, slots: newSlots };
    }
    return availability;
  });
  setAvailabilities(newAvailabilities);
};

  const fetchTimezones = async () => {
    try {
      const response = await fetch(`${Server}/meeting/timezone`);
      const data = await response.json();
      setTimezones(data);
    } catch (error) {
      console.error('Error fetching timezones:', error);
    }
  };
console.log(availabilities,"availablities are ==>")
const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

const handleCopyIconClick = (sourceDay,event) => {
  setSelectedSourceDay(sourceDay); // Set the day to copy times from
  setShowCopyDropdown(!showCopyDropdown); // Toggle dropdown visibility
  const iconPosition = event.currentTarget.getBoundingClientRect(); // Now event is passed correctly
  
  setDropdownPosition({
    top: iconPosition.bottom + window.scrollY,
    left: iconPosition.left + window.scrollX,
  });
  setShowCopyDropdown(true);
  setSelectedSourceDay(sourceDay);
};

const applyTimesToSelectedDays = () => {
  const selectedSource = availabilities.find(avail => avail.day === selectedSourceDay);
  if (selectedSource && selectedSource.slots.length > 0) {
    const sourceSlots = selectedSource.slots.map(slot => ({ ...slot }));
    const newAvailabilities = availabilities.map(avail => {
      if (selectedDays.includes(avail.day)) {
        return { 
          ...avail, 
          slots: sourceSlots, // Apply copied slots
          checked: true // Set the checkbox to be selected
        };
      }
      return avail;
    });
    setAvailabilities(newAvailabilities);
    setShowCopyDropdown(false); // Hide dropdown after applying
  
  }
  
};
const transformAvailability = (data) => {
  const result = daysOfWeek.map(day => {
    const dayData = data.filter(item => item.day === day);

    if (dayData.length > 0) {
      // If the day has slots, return them with checked as true
      const slots = dayData.map(item => ({ startTime: item.start_time, endTime: item.end_time }));
      return {
        day,
        checked: true,
        slots
      };
    } else {
      // If the day has no slots, return an empty slot with checked as false
      return {
        day,
        checked: false,
        slots: [{ startTime: '', endTime: '' }]
      };
    }
  });

  return result;
};








  return (
    <div className="page-main">
        <div className='page-inner-main'>
             <div className='email-div'>
                <div>
                <form className="custom-email-form" onSubmit={handleSubmit}>
      <div className='email-heading'>Set Your Availability</div>
      <div className='timezone-outer'>
 
       <div className='timezone-inner flex-center'>
       <p class="timezone-label" id="timezone-label">Timezone</p>
       <div>
       <div className='time_zone'>
      
      <select className="timezone-inner2 control" value={currentUserTimezone} onChange={handleTimezoneChange}>
        {Object.entries(timezones).map(([region, zones]) => (
          <optgroup key={region} label={region}>
            {Object.entries(zones).map(([zone, currentTime]) => (
              <option key={zone} value={zone}>
                {zone} &nbsp; ({currentTime})
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
    
    </div>
     
       </div>
    </div>
 
      {availabilities.map((availability, dayIndex) => (
          <div key={availability.day} className="availability mb-3 flex">

              <label>
              <input
              className='custom-checkbox check'
                type="checkbox"
                checked={availability.checked}
                onChange={() => handleCheckboxChange(dayIndex)}
              />
              {availability.day}
            </label>

            {availability.checked && (
              <>
              <div className='slot-main'>

         
            {availability.slots.map((slot, slotIndex) => (
              
              <div key={slotIndex} className="slot">
               <label>
                 <span>Start Time</span> 
                  <select
                  className='time-select'
                    value={slot.startTime}
                    onChange={(e) => handleTimeChange(dayIndex, slotIndex, 'startTime', e.target.value)}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </label>
                <label>
                <span>End Time</span> 
                  <select
                   className='time-select'
                    value={slot.endTime}
                    onChange={(e) => handleTimeChange(dayIndex, slotIndex, 'endTime', e.target.value)}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </label>
                
            <button type="button" className='cross' onClick={() => removeSlot(dayIndex, slotIndex)} ><img  className="add_icon" src={trash} alt=""></img></button>
          
              </div>
            ))}
            </div>
            
            <div className='flex justify-around cpy_add_icon'>
            {/* <button type="button" className='email-btn1 plus'  onClick={() => addSlot(dayIndex)}></FontAwesomeIcon></button> */}
            {/* <CopyTimesDropdown/> */}
            <div>
            {/* <div>{dropdownPosition}</div> // This will cause the error because dropdownPosition is an object */}

            <svg className="h-7 w-[25px] text-[#000] cursor-pointer " onClick={(event) => handleCopyIconClick(availability.day,event)}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
            </div>
            <button type="button" className='email-btn1 plus'  onClick={() => addSlot(dayIndex)}><img  className="add_icon" src={add} alt=""></img></button>
            </div>
            </>
          )}
          </div>

        ))}

{showCopyDropdown &&

             <div className="absolute mt-2 z-10 bg-[#fff]"
             style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left
            }} // Apply dropdown position inline styles here
             >
              
           
              <CopyTimesDropdown
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                applyTimesToSelectedDays={applyTimesToSelectedDays}
                selectedSourceDay={selectedSourceDay}
                setSelectedSourceDay={setSelectedSourceDay}
              />
            
            </div> 
            }
        <div className='btn-div'>
        <button type="submit" className="email-btn">
          {isLoading?<ClipLoader size={20} color="#ffffff" />:'Save'}</button>
          <ToastContainer
                                    position="top-right"
                                    autoClose={2000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    theme="light"
                                    pauseOnHover
                                />
        </div>
        
    </form>
                </div>

             </div>
        </div>
    </div>
  )


}


const CopyTimesDropdown = ({ selectedDays, setSelectedDays, applyTimesToSelectedDays, selectedSourceDay, setSelectedSourceDay }) => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handleSelectDay = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };
  return (
    
    <div className="dropdown p-2">
      
      <h3 className='text-sm text-[#198ef9]'>Apply {selectedSourceDay.charAt(0).toUpperCase() + selectedSourceDay.slice(1)}'s Times to:</h3>
      {daysOfWeek.filter(day => day !== selectedSourceDay).map(day => (
        <div key={day}>
          <input
            type="checkbox"
            checked={selectedDays.includes(day)}
            onChange={() => handleSelectDay(day)}
            className='mr-[9px]'
          />
          <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
        </div>
      ))}
      <button onClick={applyTimesToSelectedDays} className="px-4 py-1 bg-[#198ef9] text-white rounded hover:bg-blue-700">Apply</button>
    </div>
  );
};

// const CopyTimes = () => {
//   const [checkedDays, setCheckedDays] = useState({
//     Mon: false,
//     Tue: false,
//     Wed: false,
//     Thu: false,
//     Fri: false,
//     Sat: false,
//     Sun: false,
//   });

//   const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

//   const handleCheckboxChange = (day) => {
//     setCheckedDays((prevCheckedDays) => ({
//       ...prevCheckedDays,
//       [day]: !prevCheckedDays[day],
//     }));
//   };
//   const handleApply = () => {
//     // Log the selected days (or handle any logic)
//     const selectedDays = Object.keys(checkedDays).filter(
//       (day) => checkedDays[day]
//     );
//     console.log("Selected days:", selectedDays);
//   };
//   return (
//     <div className="container mx-auto mt-2">
//       <table className="w-[50px] bg-[#fff] border-2 border-gray-200 rounded-[12px] 	">
//         <thead>
//           <tr>
//             <th className="w-[70px] text-[14px] px-2 py-3 text-left text-[#3ca2fe] border-b ">
//               Copy Times To
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {daysOfWeek.map((day) => (
//             <tr key={day} className="border-b">
//               <td className="px-2 py-1">{day}</td>
//               <td className="px-2 py-1">
//                 <input
//                   type="checkbox"
//                   className="form-checkbox h-5 w-5 text-blue-600"
//                   checked={checkedDays[day]}
//                   onChange={() => handleCheckboxChange(day)}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
        
//         <div className=" mt-6 text-center ml-[32px] mb-1">
          
//           <button
//             onClick={handleApply}
//             className="px-6 py-2 bg-[#198ef9] text-white rounded hover:bg-blue-700"
//           >
//             Apply
//           </button>
//         </div>
       
//       </table>
//     </div>
//   );
// };

// const CopyTimesDropdown = () => {
//   // State to control visibility of the dropdown
//   const [isDropdownVisible, setDropdownVisible] = useState(false);

//   const toggleDropdown = () => {
//     setDropdownVisible(!isDropdownVisible);
//   };

//   return (
//     <div className="relative">
//       {/* Copy Icon */}
//       <svg class="h-7 w-[25px] text-gray-500"    onClick={toggleDropdown}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
      
     
      
//         </svg>
//       {/* Dropdown with CopyTimes */}
//       {isDropdownVisible && (
//         <div className="absolute  z-10">
//           <CopyTimes />
//         </div>
//       )}
//     </div>
//   );
// };












export default Accessability