// import "./styles.css";

import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Events from './Events';
import form from "../images/form.svg"
import calendarDay from "../images/calendar-day.svg"
import calendarClock from "../images/calendar-clock.svg"
import link from"../images/link-1.svg"

import {
  RiHome4Line,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiPlantLine,
  RiStackLine,
  RiUserUnfollowLine
} from "react-icons/ri";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  Logo,
  LogoText,
  DropdownItem,
  InputItem
  //useProSidebar
} from "react-pro-sidebar";
function Sidebars({children}) {


    const location = useLocation();
    

 
  //const { collapseSidebar } = useProSidebar();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  // const imgSrc = { link: '../images/link-1.svg' };
  useEffect(() => {
    setActiveMenu(location.pathname); // Compare with the full path
  }, [location.pathname]);

  const sideBarMenu =  [
    {
      //  imgSrc: { link: '../images/link-1.svg' }
     
        imgSrc:  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8,12h-2c-1.103,0-2,.897-2,2v2c0,1.103.897,2,2,2h2c1.103,0,2-.897,2-2v-2c0-1.103-.897-2-2-2Zm-2,4v-2h2v2s-2,0-2,0ZM19,2h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Z"/></svg>
  , // Assuming Dash is the import of your image file
        href: "/",
        active: window.location.pathname === "/",
        altText: "Dashboard", // Add alt text for accessibility
      },
      {
        imgSrc:<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg>
        , // Assuming Dash is the import of your image file
        href: "/access",
        active: window.location.pathname === "/access",
        altText: "Availability", // Add alt text for accessibility
      },
    {
      //  imgSrc: { link: '../images/link-1.svg' }
     
        imgSrc:  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8,12h-2c-1.103,0-2,.897-2,2v2c0,1.103.897,2,2,2h2c1.103,0,2-.897,2-2v-2c0-1.103-.897-2-2-2Zm-2,4v-2h2v2s-2,0-2,0ZM19,2h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Z"/></svg>
        , // Assuming Dash is the import of your image file
        href: "/eventtype",
        active: window.location.pathname === "/eventtype",
        altText: "Event Type", // Add alt text for accessibility
      },
    {

      imgSrc: <svg id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m10 10a3 3 0 0 0 0 6h4a3 3 0 0 0 0-6zm5 3a1 1 0 0 1 -1 1h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 1 1zm2 6a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm2.536-15.879-1.658-1.656a4.968 4.968 0 0 0 -3.535-1.465h-6.343a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5v-12.343a4.968 4.968 0 0 0 -1.464-3.536zm-1.414 1.414a3.1 3.1 0 0 1 .378.465h-2.5v-2.5a2.932 2.932 0 0 1 .464.377zm.878 14.465a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3z"/></svg>,
      href: "/events",
      active: window.location.pathname === "/events",
      altText: "Events",
       // Add alt text for accessibility
    },
   
//       {
//         imgSrc:<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8,12h-2c-1.103,0-2,.897-2,2v2c0,1.103.897,2,2,2h2c1.103,0,2-.897,2-2v-2c0-1.103-.897-2-2-2Zm-2,4v-2h2v2s-2,0-2,0ZM19,2h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Z"/></svg>
// , // Assuming Dash is the import of your image file
//         href: "/events",
//         active: window.location.pathname === "/events",
//         altText: "Meetings", // Add alt text for accessibility
//       },

      {
        imgSrc:<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8,12h-2c-1.103,0-2,.897-2,2v2c0,1.103.897,2,2,2h2c1.103,0,2-.897,2-2v-2c0-1.103-.897-2-2-2Zm-2,4v-2h2v2s-2,0-2,0ZM19,2h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Z"/></svg>
, // Assuming Dash is the import of your image file
        href: "/createdEvents",
        active: window.location.pathname === "/createdEvents",
        altText: "Meetings", // Add alt text for accessibility
      },

      {
               imgSrc: <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8,12h-2c-1.103,0-2,.897-2,2v2c0,1.103.897,2,2,2h2c1.103,0,2-.897,2-2v-2c0-1.103-.897-2-2-2Zm-2,4v-2h2v2s-2,0-2,0ZM19,2h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Z"/></svg>
         ,// Assuming Dash is the import of your image file
        //  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" id="calendar" viewBox="0 0 24 24">
        //        <path d="m1303 964-512 512q-10 9-23 9t-23-9l-288-288q-9-10-9-23t9-22l46-46q9-9 22-9t23 9l220 220 444-444q10-9 23-9t22 9l46 46q9 9 9 22t-9 23zM128 1664h1408V640H128v1024zM512 448V160q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v288q0 14 9 23t23 9h64q14 0 23-9t9-23zm768 0V160q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v288q0 14 9 23t23 9h64q14 0 23-9t9-23zm384-64v1280q0 52-38 90t-90 38H128q-52 0-90-38t-38-90V384q0-52 38-90t90-38h128v-96q0-66 47-113T416 0h64q66 0 113 47t47 113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z"></path>
        //      </svg>
                href: "/calendar",
                active: window.location.pathname === "/calendar",
                altText: "Calendar", // Add alt text for accessibility
              },
    // Add more menu items if needed
    
  ]

//   return (
  
    


//     <div style={{display:"flex", height:"100vh"}}>
//    <aside>
//       <Sidebar
//         className='sidebar-main sidebar-fixed'
//         style={{ height: "100vh", position:"relative" }}
//         collapsed={collapsed}
//         toggled={toggled}
//         handleToggleSidebar={handleToggleSidebar}
//         handleCollapsedChange={handleCollapsedChange}
//       >
//         <main>
//           <Menu>
          
//             {collapsed ? (
//            <div className="logo" onClick={handleCollapsedChange}>
//              {<FiChevronsRight />}
                
//            </div>
                
              
//             ) : (
//               <div
//                 className='logo'
//                 onClick={handleCollapsedChange}
//               >
//                 Planly       <FiChevronsLeft />
//               </div>
//             )}
//             {/* <hr /> */}
//           </Menu>

//           <Menu>
//           {sideBarMenu?.map((menu, i) => (
//                  <MenuItem
//                  icon={<RiHome4Line />}
//                  key={`sideBarMenuItem${i}`}
//                  className={`menu-item ${activeMenu === menu.href ? 'active-menu' : ''}`}
//                >
//                  <Link to={menu.href}>
//                    <div className="tooltip-container">
//                      <img src={menu.imgSrc} alt="" className='sidebar-img' />
//                      <span className="tooltip-text">{menu.altText}</span>
//                    </div>
//                  </Link>
//                </MenuItem>))}
//             {/* <MenuItem icon={<RiHome4Line />}>Dashboard</MenuItem>
//             <SubMenu defaultOpen label={"Professors"} icon={<RiTeamLine />}>
//               <MenuItem icon={<RiUserFollowLine />}>Active </MenuItem>
//               <MenuItem icon={<RiUserUnfollowLine />}>Ex Professors</MenuItem>
//               <MenuItem icon={<RiCalendar2Line />}>Probation Period</MenuItem>
//             </SubMenu>
//             <SubMenu defaultOpen label={"Records"} icon={<RiFolder2Line />}>
//               <MenuItem icon={<RiStackLine />}>Senior Students</MenuItem>
//               <MenuItem icon={<RiPlantLine />}>Junior Students</MenuItem>
//             </SubMenu> */}
//           </Menu>
//         </main>
//       </Sidebar>
// </aside>
//       <div  className='col-m-10' style={{display:"flex",flexDirection:"column", width:"-webkit-fill-available"}}>
//         <Header />
//       <main style={{width:"100%"}}>{children}</main>
//       </div>
      


//     </div>
   
//   );
return(
  <div>
    
    <div className='d-flex justify-content-between min-vh-100'>
    <aside>
       <Sidebar
         className='sidebar-main sidebar-fixed position-relative min-vh-100'
        //  style={{ height: "100vh", position:"relative" }}
         collapsed={collapsed}
         toggled={toggled}
         handleToggleSidebar={handleToggleSidebar}
         handleCollapsedChange={handleCollapsedChange}
       >
         <main>
           <Menu>
          
             {collapsed ? (
           <div className="logo" onClick={handleCollapsedChange}>
              {<FiChevronsRight />}
                
            </div>
                
              
             ) : (
               <div
                 className='logo'
                 onClick={handleCollapsedChange}
               >
                <img   className='logo' src="./filofax.png" />  <FiChevronsLeft />
               </div>
             )}
             {/* <hr /> */}
           </Menu>

           <Menu>
           {sideBarMenu?.map((menu, i) => (
                  <MenuItem
                
                  key={`sideBarMenuItem${i}`}
                  className={`menu-item ${activeMenu === menu.href ? 'active-menu' : ''}`}
                >
                  <Link to={menu.href}>
                    <div className="tooltip-container">
                      <div className='sidebar-img'>
                        {menu.imgSrc}
                        </div>
                      {/* <img src={menu.imgSrc} alt=""  /> */}
                      <span className="tooltip-text">{menu.altText}</span>
                    </div>
                  </Link>
                </MenuItem>))}
              {/* <MenuItem icon={<RiHome4Line />}>Dashboard</MenuItem>
             <SubMenu defaultOpen label={"Professors"} icon={<RiTeamLine />}>
              <MenuItem icon={<RiUserFollowLine />}>Active </MenuItem>
               <MenuItem icon={<RiUserUnfollowLine />}>Ex Professors</MenuItem>
               <MenuItem icon={<RiCalendar2Line />}>Probation Period</MenuItem>
             </SubMenu>
             <SubMenu defaultOpen label={"Records"} icon={<RiFolder2Line />}>
               <MenuItem icon={<RiStackLine />}>Senior Students</MenuItem>
               <MenuItem icon={<RiPlantLine />}>Junior Students</MenuItem>
             </SubMenu>  */}
          </Menu>
        </main>
       </Sidebar>
     </aside>
       <div  className='col-m-10' style={{display:"flex",flexDirection:"column", width:"-webkit-fill-available"}}>
        <Header />
       <main style={{width:"100%"}}>{children}</main>
      </div>
      


    </div>
  </div> 
)
}
export default Sidebars;
