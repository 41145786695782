import React, { useState, useMemo, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Server } from '../Config/Server';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import { RiDeleteBin5Fill } from "react-icons/ri";
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { FaTrash } from 'react-icons/fa'; // Font Awesome Trash Icon
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



const CreatedEvents= ({ deleteRow }) => {
  const storedVenueId = localStorage.getItem('Venue');
  const [venueId, setVenueId] = useState(storedVenueId)
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteBookingId,setDeleteBookingId]=useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const user_id=localStorage.getItem('LoginId');
  const sanitizedUser = user_id.replace(/"/g, '');
  const [showPopup, setShowPopup] = useState(false); // To toggle the popup
  const [idToDelete, setIdToDelete] = useState(null); // To store the row that will be deleted
  // const cardStyles = {
  //   boxShadow: '0 0 20px rgba(255, 105, 180, 0.8)', // Bright pink shadow
  //   borderRadius: '8px',
  //   padding: '16px',
  //   backgroundColor:'transparent'
  //   // Set the desired background color
  //   // Other styling properties...
  // };

  useEffect(() => {
    
 
  loadBooking();
 
    
  }, []);


  const openDeleteModal = (bookingId) => {
    setDeleteBookingId(bookingId)
    setIsDeleteOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteBookingId(null)
    setIsDeleteOpen(false);
    // section();
  };

  const loadBooking = async () => {
    const req = {
    user_id:sanitizedUser
    };
    try {
      const res = await axios.post(`${Server}/meeting/eventList`,req)
      console.log(res)
      setData(res.data.Info);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // const handleDelete = async(id) => {
  //   console.log(id , "id is ==>>")
  //   try {
  //     // const res = await axios.post(`${Server}/meeting/eventList`,req)
  //     const res = await axios.delete(`${Server}/meeting/meetings/${id}`).then(()=>{
  //       loadBooking();
  //     })
  //     // const req = await axios.post(`${Server}/meeting/deleteEvent`,req)
  //     console.log("data has been deleting ")
      
  
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  
  //   };
  // Function to open the confirmation popup
  // const handleDeleteClick = (row) => {
  //   setIdToDelete(row); // Store the row for deletion
  //   setShowPopup(true); // Show the confirmation popup
  // };
  const handleDeleteClick = (id) => {
    console.log("Selected ID for deletion:", id); // Debug log
    setIdToDelete(id); // Store the ID of the booking to delete
    setShowPopup(true); // Open the confirmation popup
  };
  const confirmDelete = async (id) => {
    if (!idToDelete) {
      console.error('No ID set for deletion'); // Log if ID is not set
      return; // Exit if there's no ID to delete
    }
    try {
      await axios.delete(`${Server}/meeting/meetings/${id}`);
      console.log(`Deleted row with id: ${id}`);
      loadBooking(); // Reload booking data after deletion
      setShowPopup(false); // Close the popup
      setIdToDelete(null); // Reset the id
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  // const confirmDelete = async (id) => {
  //   try {
  //     // Make API call to delete using the id
  //     const res = await axios.delete(`${Server}/meeting/meetings/${id}`);
  //     console.log(`Deleted row with id: ${id}`);
  //     loadBooking(); // Reload booking data after deletion
  //     setShowPopup(false); // Close the popup
  //     setIdToDelete(null); // Reset the id
  //   } catch (error) {
  //     console.error('Error deleting data:', error);
  //   }
  // };
  // Function to cancel deletion
  const cancelDelete = () => {
    setShowPopup(false); // Just hide the popup
    setIdToDelete(null); // Reset the row to delete
  };

  const columns = useMemo(
    () => [
      { Header: 'Host', accessor: 'host' },
    //   {
    //     Header: 'NAME',
    //     accessor: 'first_name',
    //     Cell: ({ row }) => {
    //       const fullName = `${row.original.first_name} ${row.original.last_name}`;
    //       return <span>{fullName}</span>;
    //     },
    //   },
  
    { Header: 'DATE', accessor: 'custom_date' },
      { Header: 'DAY', accessor: 'day' },
      { Header: 'DURATION', accessor: 'duration' },
      { Header: 'FROM', accessor: 'time' },
      { Header: 'TO', accessor: 'time_slot' },
      { Header: 'TIMEZONE', accessor: 'timezone' },
      { Header: 'LEAD', accessor: 'lead_name' },
      { Header: 'LEAD EMAIL', accessor: 'lead_email' },
      // { Header: 'ACTION', accessor: 'delete' },
    //   {
    //     Header: 'ACTION',
    //     accessor: 'id', // Assuming 'id' is the property containing the event ID
    //     Cell: ({ value }) => (
          
    //        <div className="flex items-center justify"  style={{ marginRight: '10px', display: 'block', margin: 'auto'}}>
    //         {/* <Button
    //         // onClick={() => handleButtonClick(value)}
    //         className="your-button-styles"
    //         color="your-button-color"
    //       >
    //         Click Me
    //       </Button> */}
      
    //        <button className="mx-2 text-[white] hover:text-red-700 text-[25px]" onClick={()=>{
    //          openDeleteModal(value)
    //        }}>
    //        <RiDeleteBin5Fill />
    //        </button>
           
         
    //        {/* <AddTable isTableOpen={isTableOpen} onRequestTableClose={closeTableModal} />
    //        <DeleteSection isOpen={isDeleteOpen} onRequestClose={closeDeleteModal} sectionId={deleteSectionId}/>
    //        <EditSection isOpen={isEditSectionOpen} onRequestClose={closeEditSectionModal} sectionId={editSectionId} /> */}
    //        </div>
    //     ),
    //   },
      // Add more columns as needed
      {
        Header: 'ACTION',
        accessor: 'delete',
        id: 'delete-action', // You can add a unique id if needed
        Cell: ({ row }) => (
          <button
            onClick={() => handleDeleteClick(row.original.id)}
            className="text-red-600 hover:text-gray-600"
          >
            <FaTrash />
          </button>
        ),
      },
    ],
    
    [deleteRow] // Correct dependency array
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageIndex: currentPageIndex,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setGlobalFilter: setTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const pageIndex = currentPageIndex !== undefined ? currentPageIndex : 0;

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    setTableGlobalFilter(e.target.value);
  };

  const filteredData = useMemo(
    () =>
      data.filter((row) =>
        columns.some(
          (column) =>
            row[column.id] !== undefined &&
            String(row[column.id]).toLowerCase().includes(globalFilter.toLowerCase())
        )
      ),
    [data, columns, globalFilter, pageIndex]
  );
  const backdropStyles = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("https://images.unsplash.com/photo-1556035511-3168381ea4d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnaHQlMjBjbHVifGVufDB8fDB8fHww")', // Replace with your image URL
    backgroundSize: 'cover',
    filter: 'blur(10px)', // Adjust the blur intensity
    zIndex: -1,
  };

  return (
    <>
 
 <div className="page-main">
        <div className='page-inner-main'>
             <div className='email-div'>
    
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%", margin:"20px"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) : ( 
          data.length!==0?(
            <div  className="inner-booking">
      <div >
        <div className='grid gap-x-8 gap-y-4'>

     
        <div className="flex flex-row font-poppins booking_serch items-center justify-between mt-10 ">
          <text
            className="txt_booking "
            
          >
            Meetings
          </text>
          <div className="bg-transparent w-[200px]">
            <input
              type="text"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
              placeholder="Search...."
              className="w-full p-2 search_bar"
            />
          </div>
        </div>
        
        <div className='table-responsive'>
        <Table {...getTableProps()} className="" >
        <thead className='bg_table'>
            {headerGroups.map((headerGroup) => (
              <tr  className="table_row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className="table_head">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          
          <tbody {...getTableBodyProps()}>
            {filteredData.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table_row_data">
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()} className="p-4">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table_row_data">
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()} className="p-4 text-center justify-center">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        </div>
        <div className="flex-shrink-0 p-4">
        <div className="pagination mt-4 mb-10 text-end">
      <button
    className="  btn_previus  ms-auto"
    
    onClick={() => {previousPage()
      setIndex(index - 1)}}
      disabled={!canPreviousPage}
    > Previous
    
    </button>
    
    {' '}
    <span  className="page_m">
      Page{' '}
      <strong>
        {index + 1} of {pageOptions.length}
      </strong>{' '}
    </span>

    <button
    className="  btn-next btn_previus "
    onClick={() => {nextPage();
      setIndex(index + 1)}}
    disabled={!canNextPage}
    > Next
    
    </button>
    
    {' '}
  </div>

  {/* <div className='demo'>
    <h3 className='txt_booking'>Event types</h3>
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <div className='bg-box'>
        <div className='date'>
          <span>20 may, 2023</span>
        </div>
        <Card.Title>Card Title</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        </div>
        <Card.Link href="#">Card Link</Card.Link>
      </Card.Body>
    </Card>   
  </div> */}



        </div>
        </div>
      </div>
    </div>):
      <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: '350px', width:"100%"}}>
      
      <h1 className='messages'> No Meetings Available!</h1>
    </div>
      )
    }
          {showPopup && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white  rounded-lg shadow-lg" style={{ position: 'absolute',top: '50%',left: '50%', transform: 'translate(-50%, -50%)', padding: '20px 35px' }}>
                      <h2 className="text-lg font-bold align-center">Are you sure ? </h2>
                      <p className='align-center'>you want to delete this</p>
                      <div className="mt-4 align-center ">
                        <button
                           onClick={()=> confirmDelete(idToDelete)} 
                          className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 mr-2"
                        > 
                        {/* // confirmDelete (filteredData.id) */}
                          Delete
                        </button>
                        <button
                          onClick={cancelDelete}
                          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          


    </div>
    </div>



    </>
  );
};

export default CreatedEvents;
