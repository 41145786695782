
import oneToone from '../images/meet_one.svg'
import Group from '../images/grop_meet.svg'
import Dashboard from './Dashboard';
import React, { useState, useMemo, useEffect } from 'react';

const EventType = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState();
  const openModalOne = () => {
    setIsModalOpen(true);
    setType("one")
  };

  const openModalGroup = () => {
    setIsModalOpen(true);
    setType("group")
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className=''>
        <div class="main_h_d container">
          <div class="dv_hed">
          <a class="spn_atri" type="button" href="/">
          <span aria-hidden="true" class="head_spn">
            <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4806 15.9941C13.8398 15.6529 13.8398 15.0998 13.4806 14.7586L8.47062 10L13.4806 5.24142C13.8398 4.90024 13.8398 4.34707 13.4806 4.00589C13.1214 3.66471 12.539 3.66471 12.1798 4.00589L6.51941 9.38223C6.1602 9.72342 6.1602 10.2766 6.51941 10.6178L12.1798 15.9941C12.539 16.3353 13.1214 16.3353 13.4806 15.9941Z" fill="currentColor"></path></svg>
        </span>Back</a>
        <div class="Main_hed_dv">
          <h1 class="Main_head" style={{color: "#000"}}>Create New Event Type</h1>
          </div>
          </div>
          </div>
        <div class="container px-2 ">
<div class="main_tb_h w-[593px] ">
  <table class="main_tb shadow-md">
    <tbody class="main_td">
      <tr data-component="one-on-one" class="table_rw">
        <td class="table_rw_d">
  <button class="table_btn" type="button" onClick={openModalOne}>
    <div class="table_btn_dv ">
 <img src={oneToone} alt="One-on-One" className='person_icon w-[60px] mx-8'/> 

</div>
<div class="table_btn_dv_main">
  <h2 class="btn_dv_hed">One-To-One</h2>
  <h3 class="btn_dv_hed2">
    <b>One host</b> with <b>one invitee</b></h3>
  <p class="btn_dv_p">Good for: coffee chats, 1:1 interviews, etc.</p>
  </div>
  <div class="btn_icon_dv">
    <span aria-hidden="true" class="btn_icon_spn">
      <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.51941 15.9941C6.1602 15.6529 6.1602 15.0998 6.51941 14.7586L11.5294 10L6.51941 5.24142C6.1602 4.90024 6.1602 4.34707 6.51941 4.00589C6.87862 3.66471 7.46101 3.66471 7.82022 4.00589L13.4806 9.38223C13.8398 9.72342 13.8398 10.2766 13.4806 10.6178L7.82022 15.9941C7.46101 16.3353 6.87862 16.3353 6.51941 15.9941Z" fill="currentColor"></path></svg>
</span>
</div>
</button>
</td>
</tr>
<tr data-component="group" class="table_rw">
  <td class="table_rw_d">
    <button class="table_btn" type="button" onClick={openModalGroup} >
      <div class="table_btn_dv">
        <img src={Group} alt="Group" className='person_icon w-[60px] mx-8 hover:sepia hover:brightness-50 transition duration-300' />
</div>
<div class="table_btn_dv_main">
  <h2 class="btn_dv_hed">Group</h2>
  <h3 class="btn_dv_hed2">
  <b>One host</b> with <b>group of invitees</b></h3>
  <p class="btn_dv_p">Good for: webinars, online classes, etc.</p>
  </div>
  <div class="btn_icon_dv">
    <span aria-hidden="true" class="btn_icon_spn">
      <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.51941 15.9941C6.1602 15.6529 6.1602 15.0998 6.51941 14.7586L11.5294 10L6.51941 5.24142C6.1602 4.90024 6.1602 4.34707 6.51941 4.00589C6.87862 3.66471 7.46101 3.66471 7.82022 4.00589L13.4806 9.38223C13.8398 9.72342 13.8398 10.2766 13.4806 10.6178L7.82022 15.9941C7.46101 16.3353 6.87862 16.3353 6.51941 15.9941Z" fill="currentColor"></path></svg>
  </span>
  </div>
  </button>
  </td>
  </tr>
  {/* <tr data-component="collective" class="table_rw">
    <td class="table_rw_d">
      <button class="table_btn" type="button">
        <div class="table_btn_dv">
          <img src="" alt="Collective"/>
</div>
<div class="table_btn_dv_main">
  <h2 class="btn_dv_hed">Collective</h2>
<h3 class="btn_dv_hed2">
  <b>More than one host</b> with <b>one invitee</b></h3>
<p class="btn_dv_p">Good for: panel interviews, group sales calls, etc.</p>
</div>
<div class="btn_icon_dv">
  <span aria-hidden="true" class="btn_icon_spn">
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.51941 15.9941C6.1602 15.6529 6.1602 15.0998 6.51941 14.7586L11.5294 10L6.51941 5.24142C6.1602 4.90024 6.1602 4.34707 6.51941 4.00589C6.87862 3.66471 7.46101 3.66471 7.82022 4.00589L13.4806 9.38223C13.8398 9.72342 13.8398 10.2766 13.4806 10.6178L7.82022 15.9941C7.46101 16.3353 6.87862 16.3353 6.51941 15.9941Z" fill="currentColor"></path></svg>
</span>
</div>
</button>
</td>
</tr>
<tr data-component="round-robin" class="table_rw">
  <td class="table_rw_d">
  <button class="table_btn" type="button">
    <div class="table_btn_dv">
    <img src="" alt="Round Robin"/>
    </div>
<div class="table_btn_dv_main">
  <h2 class="btn_dv_hed">Round Robin</h2>
<h3 class="btn_dv_hed2">One <b>rotating host</b> with <b>one invitee</b></h3>
<p class="btn_dv_p">Good for: distributing incoming sales leads</p>
</div>
<div class="btn_icon_dv">
  <span aria-hidden="true" class="btn_icon_spn">
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.51941 15.9941C6.1602 15.6529 6.1602 15.0998 6.51941 14.7586L11.5294 10L6.51941 5.24142C6.1602 4.90024 6.1602 4.34707 6.51941 4.00589C6.87862 3.66471 7.46101 3.66471 7.82022 4.00589L13.4806 9.38223C13.8398 9.72342 13.8398 10.2766 13.4806 10.6178L7.82022 15.9941C7.46101 16.3353 6.87862 16.3353 6.51941 15.9941Z" fill="currentColor"></path></svg>
</span>
</div>
</button>
</td>
</tr> */}
</tbody>
</table>
{/* <div class="meet_main">
  <p class="meet_main_p" style={{color: "var(--colorTextSecondary, var(--coreColorNeutral600))"}}>More ways to meet</p>
<div class="meet_main_dv">
  <button class="meet_btn" type="button">
  <div>
  <p class="meet_main_p" style={{color: "currentcolor"}}>One-off meeting</p>
<p class="meet_btn_p" style={{color: "currentcolor"}}>Invite someone to pick a time with you without creating an Event Type.</p>
</div>
<div class="meet_btn_txt">Create</div>
</button>
<button class="meet_btn" type="button">
  <div>
  <p class="meet_main_p" style={{color: "currentcolor"}}>Meeting poll</p>
  <p class="meet_btn_p" style={{color: "currentcolor"}}>Let your group of invitees vote on a time that works for everyone.</p></div>
  <div class="meet_btn_txt">Create</div>
  </button>
  </div>
  </div> */}
  </div>
</div>
</div>
<Dashboard isOpen={isModalOpen}  onRequestClose={closeModal} type={type} />

    </div>
  )
}

export default EventType