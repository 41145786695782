import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // For basic styling
// import your custom styles (if using Tailwind, or you can override default classes)
import "./Style.css";

import axios from "axios";
const localizer = momentLocalizer(moment);

const Eventcalendar = () => {
  const [events, setEvents] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const logId=localStorage.getItem('LoginId')
  const sanitizedId = logId.replace(/"/g, '');
  useEffect(() => {
    const payload = {
      // name: 'John Doe',
      // email: 'johndoe@example.com',
      user_id: sanitizedId,
    };

    axios
      .post("https://mern.equasar.com/meeting/eventList", payload)
      .then((response) => {
        console.log("API Response:", response.data); // Log API response
        const formattedEvents = response.data.Info.map((event) => ({
          title: event.eventName,
          start: new Date(event.date),
          end: new Date(event.date),
        }));
        console.log("Formatted Events:", formattedEvents); // Log formatted events
        setEvents(formattedEvents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error); // Log errors
        setError(error);
        setLoading(false);
      });
  }, []); // Empty array to run the effect only once on component mount

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="error_">
        Error: {error.message}
       
      </div>
    );
  }

  return (
    <div>
      <div className="container mx-auto p-4">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["day", "week", "work_week", "month", "agenda"]}
          style={{ height: 600 }}
          className="shadow-lg bg-white rounded-lg"
        />
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </div>
      
    </div>
  );
};

export default Eventcalendar;