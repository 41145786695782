import React,{useState,useEffect} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import CryptoJS from 'crypto-js';

// import svg from "./Arrow-45.png"
// import { Server } from '../config/server';
import { useParams, useNavigate } from 'react-router-dom';
import { setMonth, setYear, getMonth, getYear } from 'date-fns';
import axios from 'axios';
import { is } from 'date-fns/locale';

function Preview({ eventName, duration, username }) {
  // const { startDate } = useParams();
//   const {data1}=useParams();
//   const navigate=useNavigate();
//   console.log(data1)
//   const [newStartDate,setNewStartDate]= useState();
//   const [monthDate,setMonthDate]= useState();
//   const [username,setUsername]= useState();
//   const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
//   const[formattedTimeSlots,setFormattedTimeSlots]=useState([]);
//   const[timezone,setTimezone]=useState();
//   // Function to encrypt data
// const encryptData = (data, key) => {
//   // return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
// };


//   const handleTimeSlotClick = (timeSlot) => {
//     setSelectedTimeSlot(selectedTimeSlot === timeSlot ? null : timeSlot);
//   };

//   console.log(newStartDate,timezone,"data which i want is as follows")
// useEffect(()=>{
//  if(newStartDate && timezone){
//   const payload={
//     date:newStartDate,
//     timezone:timezone,
//     username:username
//   }
//   console.log(payload,"payload is ")
//   // axios.post(`${Server}/meeting/timeslots`,payload).then((data)=>{
//   // console.log(data,"api response for the timezone is as follows")
//   // setFormattedTimeSlots(data.data.data)

//   // })
//  }
 
// },[newStartDate,timezone])



// useEffect(()=>{
//   const deserializeStringToDate = (dateString) => {
//     return new Date(dateString); // Parse ISO 8601 format string
//   };
//    // Function to decrypt data
// const decryptData = (data1, key) => {
//   // const bytes = CryptoJS.AES.decrypt(data1, key);
//   // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };
// const secretKey = 'itsmehere';
// // Decrypt the data
// const decryptedData = decryptData(decodeURIComponent(data1), secretKey)
// console.log(decryptedData[0],"decrypted data is ===>>>")
// const deserializedDate = deserializeStringToDate(decryptedData[0].startDate);
// setUsername(decryptedData[0].username)
// setNewStartDate(deserializedDate);
// setTimezone(decryptedData[0].timeSlot)
// const DateWithMonthName = moment(deserializedDate).format('MMMM DD, YYYY'); // Format: March 29, 2024

//  setMonthDate(DateWithMonthName)


// },[])
  

// const DayName = moment(newStartDate).format('dddd'); // Format: Friday, March 29, 2024
   
//     const handleNextClick = (timeSlot) => {
//       console.log(timeSlot)


//       const originalData = [{day:DayName,date:monthDate,timeSlot:timeSlot,timezone:timezone ,username:username}];
//       const secretKey = 'itsmehere';
//       const encryptedData = encryptData(originalData, secretKey);






//       const encodedData = encodeURIComponent(encryptedData);
//       navigate(`/tspe4/${encodedData}`);
//       // window.location.href = `/tspe4/${encodeURIComponent(encryptedData)}`;
      
//     };
 
    return (
      <div className='outer'>
  <div className='inner1'>

  <div className='inner2'>
    <div className='mainss'>
   <div className='step2-main'>
    <div className='step2-head'>
        <div className='step2-top-div'>
        <a href="/" className='step2-hidden'>
         <span className='step2-previous'>
            <img className='step2-previous-img' src="" alt=""/>
         </span>
        
            </a>
            <div className='step2-sec-div'>
              <div className='step2-sec-div2'>{username}</div>
              <h1 className='step2-sec-div-h1'>{eventName || 'Event Name '}</h1>
              <div className='step2-sec-div-h1-cus'>
                Meeting Duration :
                {duration || '1 hr'}
              </div>
              {/* <div className='step2-sec-inner-div'>
                <div className='step2-sec-inner-div2'>
                    <div className='step2-sec-inner-div3'>
                       <div className='step2-sec-inner-div3-timer'>
                        
                         <span className='step2-sec-inner-div3-span'>
                         <svg data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img"><path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 3.269V5l1.759 2.052" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                         </span>
                       </div>
                     Meeting Duration   1 hr
                    </div>
                    
                </div>

              </div> */}
            </div>
           
        </div>
        
      
 
    </div>

   </div>
   <div className='step2-sec-main'>
      <div className='spotpicker'>
         <div className='spotpicker-inner'>
           <div className='spotpicker-date'>
             <h2 className='spotpicker-label test'>Select a Day</h2>
              <div className='spotpicker-date-div2 date4' style={{ position: 'relative' }}>
              <DatePicker 
                          
                           
                            wrapperClassName="custom-datepicker-wrapper"
                            selectsRange
                            inline
                            disabled // This locks the DatePicker, preventing any selection
                            // style={{
                            //   width: '100px',
                            //   height: '20px',
                            //   fontSize: '46px'
                            // }}
                          />
                           <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent',
            zIndex: 1,
          }} />
 
    <div className='timezone-outer'>
 
       <div className='timezone-inner'>
       <p class="timezone-label" id="timezone-label">Time zone</p>
       <div>
       <div>
      
      <select className="timezone-inner2" >
      
              <option key="1" value="1">
                Asia/Kolkata
              </option>
          
  
      </select>
    </div>
    
    </div>
     
       </div>
    </div>
              </div>
           </div>

        </div>

      </div>
   </div>
   </div>






    </div>
    </div>
    </div>
      )
}

export default Preview 