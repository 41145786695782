import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./Pages/Sidebar";
import Dashboard from "./Pages/Dashboard";
import Accessability from "./Pages/Accessability";
import Header from "./Pages/Header";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import CustomEmail from "./Pages/CustomEmail";
import Events from './Pages/Events'
import CreatedEvents from "./Pages/CreatedEvents";
import Preview from "./Pages/Preview";
import Calendar from "./Pages/Calendar";
import EventType from "./Pages/EventType";
import Admindashboard from "./Pages/Admin_dashboard1";
import DashboardDefault from "../src/Pages/dashboard2";



function ProjectRoutes() {
  const auth = localStorage.getItem("Name");
  return (
  <Router>
    {auth?
    
    (
<Sidebar>
<Routes>
 <Route path="/" element={<DashboardDefault />} />
 <Route path="/access" element={<Accessability />} />
 <Route path="/header" element={<Header />} />
 <Route path="/email" element={<CustomEmail />} />
 <Route path="/preview" element={<Preview/>} />
 <Route path="/eventtype" element={<EventType />} />
  <Route path="/calendar" element={<Calendar />} /> 
 <Route path="/createdEvents" element={<CreatedEvents/>}/>
 {/* <Route path="/admindashboard" element={<Admindashboard/>} /> */}
 <Route path="/events" element={<Events/>} />  
 
 </Routes>



    </Sidebar> 
  )
      :(
        <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/signup" element={<Signup/>} />
        </Routes>
      )}
  </Router>
  )
}

export default ProjectRoutes